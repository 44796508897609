<template>
    <v-form v-model="valid">
        <v-container v-if="currentUserData">
            <v-row>
                <v-col :cols="hasLogo ? 11 : 12">
                    <v-file-input
                        v-model="logo"
                        :label="hasLogo ? $t('settings.upload_new_logo') : $t('settings.upload_logo')"
                        persistent-hint
                        single
                        dense
                        outlined
                        accept="image/x-png,image/gif,image/jpeg"
                        prepend-icon=""
                        append-icon="mdi-camera"
                        clearable
                        :rules="[...requiredRules, ...logoRules]"
                    ></v-file-input>
                </v-col>
                <v-col v-if="hasLogo" cols="1">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="checkLogo"><v-icon>mdi-eye</v-icon></v-btn>
                        </template>
                        <span>{{ $t('settings.check_logo') }}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12">
                    <v-card-actions>
                        <submit-button v-if="hasLogo" color="error" :loading="loading" @click="deleteLogo">
                            {{ $t('settings.remove_logo') }}
                        </submit-button>
                        <v-spacer />
                        <submit-button :loading="loading" :disabled="!valid" @click="handleSubmit">
                            {{ $t('general.save') }}
                        </submit-button>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
export default {
    mixins: [formRulesMixin],
    data() {
        return {
            loading: false,
            logo: null,
            valid: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        hasLogo() {
            if (!this.currentUserData) {
                return false;
            }
            return Boolean(this.currentUserData.settings.logoUrl);
        }
    },
    methods: {
        ...mapActions('auth', ['changeLogo', 'removeLogo']),
        checkLogo() {
            window.open(this.currentUserData.settings.logoUrl, '_blank');
        },
        async handleSubmit() {
            this.loading = true;
            try {
                const url = (
                    await this.changeLogo({
                        logo: this.logo,
                        userId: this.currentUserData.id
                    })
                ).url;

                // Request succeeded
                this.$notify({
                    title: this.$t('settings.company_logo_succesfuly_changed'),
                    text: this.$t('settings.company_logo_succesfuly_changed'),
                    type: 'success'
                });

                this.currentUserData.settings.logoUrl = url;
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async deleteLogo() {
            this.loading = true;
            try {
                await this.removeLogo();

                // Request succeeded
                this.$notify({
                    title: this.$t('settings.company_logo_succesfuly_deleted.title'),
                    text: this.$t('settings.company_logo_succesfuly_deleted.content'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
