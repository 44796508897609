<template>
    <v-form v-model="valid" @keyup.native.enter="changePassword">
        <v-row>
            <v-col cols="12">
                <v-alert dense type="info">
                    Het veranderen van het e-mailadres van je profiel is tijdelijk uitgeschakeld in functie van een
                    update die we aan het uitvoeren zijn. Je kan het e-mailadres dat wordt vermeld op je offerte,
                    verkoopfactuur of creditnota wel aanpassen via 'instellingen' -> 'verkoopfactuur'.
                </v-alert>
                <v-simple-table v-if="changeEmailRequest">
                    <template #default>
                        <tbody>
                            <tr>
                                <td class="text-left">
                                    {{ changeEmailRequest.newEmail }}
                                    <i>({{ $t('settings.changeEmail.waiting_for_verification') }})</i>
                                </td>
                                <td>
                                    <v-btn icon color="red" @click="deleteChangeEmailRequest">
                                        <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-row v-if="false">
            <v-col cols="12">
                <text-field
                    v-model="newEmail"
                    :label="$t('changeEmail.new_email')"
                    name="newEmail"
                    prepend-inner-icon="mdi-email"
                    outlined
                    type="email"
                    persistent-hint
                    readonly
                    disabled
                    background-color="rgba(0, 0, 0, 0.12)"
                    required
                />
            </v-col>
            <v-col cols="12">
                <v-card-actions>
                    <v-spacer />
                    <submit-button color="primary" disabled @click="handleSubmit">
                        {{ $t('general.save') }}
                    </submit-button>
                </v-card-actions>
            </v-col>
        </v-row>
        <loader v-if="loading" />
    </v-form>
</template>

<script>
// --- State ---
// --- Mixins ---
import Loader from '@/components/Loader';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import {
    getChangeEmailRequestRequest,
    sendChangeAccountEmailRequest,
    sendDeleteAccountEmailRequest
} from '@/services/usersettings';

export default {
    components: { Loader },
    mixins: [formRulesMixin],

    data() {
        return {
            newEmail: '',
            changeEmailRequest: null,
            valid: true,
            loading: false
        };
    },

    async created() {
        try {
            this.loading = true;
            await this.refreshData();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        async refreshData() {
            this.loading = true;
            try {
                this.changeEmailRequest = await getChangeEmailRequestRequest();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async handleSubmit() {
            this.loading = true;
            try {
                await sendChangeAccountEmailRequest({
                    email: this.newEmail
                });
                notify.call(this, {
                    title: this.$i18n.t('settings.succesfuly_updated'),
                    text: this.$i18n.t('settings.succesfuly_updated'),
                    type: 'success'
                });

                await this.refreshData();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async deleteChangeEmailRequest() {
            this.loading = true;
            try {
                await sendDeleteAccountEmailRequest(this.changeEmailRequest.id);
                notify.call(this, {
                    title: this.$i18n.t('settings.succesfuly_updated'),
                    text: this.$i18n.t('settings.succesfuly_updated'),
                    type: 'success'
                });

                await this.refreshData();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
