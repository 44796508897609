<template>
    <v-form v-if="currentUserData" v-model="valid">
        <v-row>
            <v-col cols="12" md="4">
                <v-checkbox v-model="isUserVatLiable" :label="$t('general.vat_liable')" />
            </v-col>
            <v-col cols="12">
                <v-card-actions>
                    <v-spacer />
                    <submit-button :loading="loading" :disabled="!valid" @click="showDialog = true">
                        {{ $t('general.save') }}
                    </submit-button>
                </v-card-actions>
            </v-col>
        </v-row>

        <confirmation-dialog
            v-if="showDialog"
            id="showLinkNotPresentDialog"
            v-model="showDialog"
            :title="$t('tooltips.settings.vatLiableSwitch.title')"
            :message="$t('tooltips.settings.vatLiableSwitch.content')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.confirm')"
            positive-color="green"
            @click-positive="handleSubmit"
            @click-negative="showDialog = false"
        />
    </v-form>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { isVatLiable } from '@/helpers/VATHelper';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';

export default {
    components: { ConfirmationDialog },
    mixins: [formRulesMixin],

    data: function () {
        return {
            loading: false,
            valid: true,
            isUserVatLiable: false,
            showDialog: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    watch: {
        currentUserData: {
            handler() {
                this.isUserVatLiable = isVatLiable(this.currentUserData.settings.vatLiable);
            },
            immediate: true
        }
    },

    methods: {
        ...mapActions('auth', ['changeVATLiable', 'getCurrentUser']),
        async handleSubmit() {
            this.loading = true;
            try {
                await this.changeVATLiable();

                this.$notify({
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated'),
                    type: 'success'
                });

                await this.getCurrentUser();

                setTimeout(() => {
                    window.location.reload();
                }, 800);

                this.showDialog = false;
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
                this.showDialog = false;
            }
        }
    }
};
</script>
