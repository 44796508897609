<template>
    <v-row v-if="currentUserData">
        <v-col cols="12" md="12">
            <v-color-picker v-model="currentUserData.settings.color" mode="hexa" />
            <v-card-actions>
                <v-spacer />
                <submit-button :loading="loading" @click="handleSubmit">
                    {{ $t('general.save') }}
                </submit-button>
            </v-card-actions>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
export default {
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData'])
    },
    methods: {
        ...mapActions('auth', ['changeCompanyColor']),
        async handleSubmit() {
            this.loading = true;
            try {
                await this.changeCompanyColor(this.currentUserData.settings.color);

                // Request succeeded
                this.$notify({
                    title: this.$t('settings.company_color_succesfuly_changed'),
                    text: this.$t('settings.company_color_succesfuly_changed'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
