
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import CompanyNumberField from '@/components/FormComponents/CompanyNumberField.vue';
import Loader from '@/components/Loader.vue';
import { isAccountant } from '@/helpers/auth';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { isEmptyOrNil } from '@/helpers/general';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { middlewareMixin, MiddlewareNextFnc } from '@/mixins/middlewareMixin';
import { sendUpdateUserBasicSettingsRequest } from '@/services/usersettings';
import { GeneralFncType } from '@/types/general';
import { IUpdateBasicInformationData } from 'dexxter-types';
import _ from 'lodash';
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';
import { mapActions, mapState } from 'vuex';
import { analyticsIdentify } from '../../services/analytics';
import DexxterStartDateForm from '../../views/registration/completeUserRegistration/components/DexxterStartDateForm/DexxterStartDateForm.vue';

export default mixins(formRulesMixin, middlewareMixin).extend({
    components: { Loader, CompanyNumberField, DexxterStartDateForm },

    mixins: [formRulesMixin, middlewareMixin],

    props: {
        userData: {
            type: Object,
            default: null
        } as PropOptions<IUpdateBasicInformationData['user']>
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    created() {
        this.submitMiddleware = [this.checkIfCompanyIsNowFilledInMiddleware, this.updateUserInfo];
    },

    data() {
        return {
            loading: false,
            valid: false,
            updateCurrentUserData: null as IUpdateBasicInformationData['user'] | null,
            submitMiddleware: [] as GeneralFncType[]
        };
    },

    watch: {
        userData: {
            immediate: true,
            handler() {
                this.updateCurrentUserData = _.cloneDeep(this.userData);
            }
        }
    },

    methods: {
        ...mapActions('auth', ['getCurrentUser']),
        async updateUserInfo() {
            this.loading = true;
            try {
                const updateData = this.updateCurrentUserData as IUpdateBasicInformationData['user'];

                await sendUpdateUserBasicSettingsRequest(updateData);
                notify.call(this, {
                    title: this.$t('settings.user_settings_saved'),
                    text: this.$t('settings.user_settings_saved_full')
                });

                // Fetch updateCurrentUserData
                await this.getCurrentUser();

                analyticsIdentify(this.currentUserData.id, {
                    Company_Number: updateData.company.number,
                    firstName: updateData.userinformation.firstName,
                    lastName: updateData.userinformation.name,
                    Enterprise_Start: updateData.settings.companyStartDate,
                    Dexxter_Start: updateData.settings.dexxterStartDate,
                    Enterprise_City: updateData.address.city,
                    Enterprise_House_Number: updateData.address.number?.toString(),
                    Enterprise_Postal_Code: updateData.address.zipcode,
                    Enterprise_Street_Name: updateData.address.street
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        checkIfCompanyIsNowFilledInMiddleware(next: MiddlewareNextFnc) {
            if (
                isEmptyOrNil(this.userData.company.number) &&
                !isEmptyOrNil(this.updateCurrentUserData?.company.number)
            ) {
                this.setDialogAttributes(
                    {
                        title: this.$t('settings.change_first_time_companynumber.title') as string,
                        dataId: 'after-companynumberfillin-steps',
                        message: this.$t('settings.change_first_time_companynumber.content') as string,
                        component: ConfirmationDialog,
                        positiveButton: 'Begrepen'
                    },
                    {
                        clickPositive: this.clickPositive.bind(this, next)
                    }
                );

                this.openConfirmationDialog();

                return;
            }

            next();
        },
        isAccountant
    }
});
