<template>
    <view-layout id="generalSettingsView">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <section v-if="currentUserData && !loading">
                <v-row>
                    <v-col cols="12" :lg="canEditBasicInformation ? 9 : 12">
                        <v-card outlined class="mb-4 pa-8">
                            <template v-if="canEditBasicInformation">
                                <h2>{{ $t('login.account') }}</h2>
                                <h3 class="my-4">
                                    {{ $t('settings.basic_information') }}
                                </h3>
                                <update-account-basic-info-form :user-data="currentUserData" dense />
                                <v-divider />
                            </template>

                            <h3 class="my-4">
                                {{ $t('settings.change_password_section') }}
                            </h3>
                            <change-password-form dense />
                            <v-divider />

                            <h3 class="my-4">
                                {{ $t('settings.change_email_section') }}
                            </h3>
                            <change-email />
                            <v-divider />

                            <template v-if="isUserAccount">
                                <base-icon-right small i18n-path="tooltips.settings.general.close_bookyear">
                                    <h3 class="my-4">
                                        {{
                                            $t('settings.close_bookyear', {
                                                year: selectedYear
                                            })
                                        }}
                                    </h3>
                                </base-icon-right>
                                <close-book-year class="mb-6" />
                                <v-divider />
                            </template>

                            <template v-if="!isFreeTier">
                                <h3 class="my-4">
                                    {{
                                        $t('settings.close_quarters', {
                                            year: selectedYear
                                        })
                                    }}
                                </h3>
                                <close-quarters class="mb-6" />
                                <v-divider />
                            </template>

                            <template v-if="isUserAccountAndCanUpdateTaxSettings">
                                <h3 class="my-4">
                                    {{ $t('settings.vat_settings') }}
                                </h3>
                                <vat-liable-form />
                                <v-divider />
                            </template>

                            <template v-if="canEditBasicInformation && currentUserData.settings">
                                <h3 class="my-4">
                                    {{ $t('settings.emailSettings.email_settings') }}
                                </h3>
                                <email-settings />
                                <v-divider />
                            </template>

                            <template
                                v-if="
                                    canEditBasicInformation &&
                                    currentUserData.settings &&
                                    isVATLiable &&
                                    canChangeVATStatus
                                "
                            >
                                <h3 class="my-4">
                                    {{ $t('settings.vat_settings') }}
                                </h3>
                                <btw-medecontractant-form />
                                <v-divider />
                            </template>

                            <template v-if="canEditBasicInformation">
                                <h3 class="my-4">
                                    {{ $t('settings.change_businss_color') }}
                                </h3>
                                <color-picker-form />
                                <v-divider />

                                <h3 class="my-4">
                                    {{ $t('settings.change_company_image') }}
                                </h3>
                                <logo-form />
                            </template>
                        </v-card>
                    </v-col>

                    <v-col v-if="canEditBasicInformation" cols="12" lg="3">
                        <v-card id="v-step-8" outlined class="d-flex flex-column justify-center align-center mb-4 pa-4">
                            <v-badge
                                :value="userBillinginfo.hasTrialSubscription()"
                                color="orange"
                                :content="$t('header.trial')"
                                overlap
                                offset-x="16"
                                class="my-4"
                            >
                                <v-avatar color="rgba(0,0,0,0.16)" size="92">
                                    {{ getCurrentUserInitials }}
                                </v-avatar>
                            </v-badge>
                            <h2 class="mt-4 text-center">
                                {{ getCurrentUserFullName }}
                            </h2>
                            <p class="text-caption">
                                {{ currentUserData.userinformation.email }}
                            </p>
                            <p class="text-caption">
                                {{ VATType }}
                            </p>
                            <v-btn
                                v-if="canDownloadUserData"
                                small
                                color="primary"
                                class="mt-3 mb-2"
                                :loading="userDataExportLoading"
                                @click="handleDownloadUserData"
                            >
                                <v-icon small left dark> mdi-download </v-icon>Gebruikersdata
                            </v-btn>
                        </v-card>
                    </v-col>
                </v-row>
            </section>
            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import UpdateAccountBasicInfoForm from '@/components/AddAccountForms/UpdateAccountBasicInfoForm.vue';
import ChangePasswordForm from '@/components/Forms/ChangePasswordForm.vue';

import BtwMedecontractantForm from '@/components/Forms/BtwMedecontractantForm.vue';
import ColorPickerForm from '@/components/Forms/ColorPickerForm.vue';
import LogoForm from '@/components/Forms/LogoForm.vue';

import Loader from '@/components/Loader.vue';
// --- State ---
import { mapActions, mapGetters, mapState } from 'vuex';

// --- Error handling ---
import VatLiableForm from '@/components/Forms/VatLiableForm.vue';
import PageHeader from '@/components/PageHeader.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { vatStatuses } from '@/config/constants';
import { isAccountant, isAdmin, isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { billingMixin } from '../../mixins/billingMixin';

import CloseBookYear from '@/components/CloseBookYear.vue';
import CloseQuarters from '@/components/CloseQuarters.vue';
import EmailSettings from '@/components/Forms/EmailSettings.vue';
import { isVatLiable } from '@/helpers/VATHelper';
import { mapFields } from 'vuex-map-fields';
import ChangeEmail from './components/ChangeEmail.vue';

export default {
    components: {
        Loader,
        ChangePasswordForm,
        BtwMedecontractantForm,
        UpdateAccountBasicInfoForm,
        ColorPickerForm,
        LogoForm,
        ViewLayout,
        PageHeader,
        VatLiableForm,
        CloseBookYear,
        CloseQuarters,
        EmailSettings,
        ChangeEmail
    },

    mixins: [billingMixin],

    data() {
        return {
            loading: false,
            currentTab: 0,
            userDataExportLoading: false,
            dialog: false,
            toInviteEmail: ''
        };
    },

    computed: {
        ...mapState('accountantUsers', ['accountants']),
        ...mapState('auth', ['currentUserData', 'userBillinginfo']),
        ...mapFields(['selectedYear']),
        ...mapGetters('auth', ['getCurrentUserInitials', 'getCurrentUserFullName']),

        VATType() {
            if (this.currentUserData.settings.vatLiable === vatStatuses.VATEXEMPT) {
                return this.$t('completeUserRegistration.VATExempt');
            } else if (this.currentUserData.settings.vatLiable === vatStatuses.NOTVATLIABLE) {
                return this.$t('completeUserRegistration.NotVATLiable');
            } else if (this.currentUserData.settings.vatLiable === vatStatuses.VATLIABLE) {
                return this.$t('completeUserRegistration.VATLiable');
            }

            return '';
        },
        canDownloadUserData() {
            return isUserAccount(this.currentUserData) === true;
        },
        isFreeTier() {
            return isUserAccountWithFreeTier(this.currentUserData) === true;
        },
        userHasAccountant() {
            return this.accountants.length > 0;
        },
        canSeeNumbers() {
            return isUserAccount(this.currentUserData) === true;
        },
        canChangeVATStatus() {
            return (
                isUserAccount(this.currentUserData) === true &&
                this.currentUserData.settings.vatLiable !== vatStatuses.VATEXEMPT
            );
        },
        isNotAdmin() {
            return isAdmin(this.currentUserData) === false;
        },
        canEditBasicInformation() {
            return isAdmin(this.currentUserData) === false && isAccountant(this.currentUserData) === false;
        },
        isVATLiable() {
            if (!isUserAccount(this.currentUserData)) {
                return false;
            }
            return isVatLiable(this.currentUserData.settings.vatLiable);
        },
        isUserAccountAndCanUpdateTaxSettings() {
            return isUserAccount(this.currentUserData) && this.$can('UPDATE', 'Taxsettings');
        },
        isUserAccount() {
            return isUserAccount(this.currentUserData);
        }
    },

    methods: {
        ...mapActions('auth', [
            'exportUserDataToExcel',
            'getCurrentUserBillinginfo',
            'previewInvoiceLayout',
            'setInvoicelayout',
            'downloadUserData'
        ]),
        ...mapActions('subscription', ['cancelSubscription', 'undoCancelSubscription']),
        async handleDownloadUserData() {
            this.userDataExportLoading = true;

            try {
                await this.downloadUserData();

                notify.call(this, {
                    title: this.$t('settings.downloadData.title'),
                    text: this.$t('settings.downloadData.content')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }

            this.userDataExportLoading = false;
        },
        clearForm() {
            this.$refs.form.clearForm();
        }
    }
};
</script>
