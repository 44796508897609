<template>
    <v-form v-if="currentUserData" v-model="valid">
        <v-row>
            <v-col cols="12" md="4">
                <v-checkbox
                    v-model="currentUserData.settings.btwMedecontractant"
                    :label="$t('accountantTerms.btw_medecontractant')"
                />
            </v-col>
            <v-col cols="12">
                <v-card-actions>
                    <v-spacer />
                    <submit-button :loading="loading" :disabled="!valid" @click="handleSubmit">
                        {{ $t('general.save') }}
                    </submit-button>
                </v-card-actions>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

export default {
    mixins: [formRulesMixin],

    data: function () {
        return {
            loading: false,
            valid: true
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions('auth', ['changeBtwMedecontractant']),
        async handleSubmit() {
            this.loading = true;
            try {
                await this.changeBtwMedecontractant(this.currentUserData.settings.btwMedecontractant);

                this.$notify({
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
