<template>
    <v-form v-model="valid">
        <v-row>
            <v-col cols="12">
                <text-field
                    v-model="replyToAddress"
                    :label="$t('settings.emailSettings.replyToAddress')"
                    name="oldpassword"
                    prepend-inner-icon="mdi-email"
                    outlined
                    type="email"
                    persistent-hint
                    :rules="emailRules"
                    required
                />
            </v-col>
            <v-col cols="12">
                <v-card-actions>
                    <v-spacer />
                    <submit-button color="primary" :disabled="!valid" @click="handleSubmit">
                        {{ $t('general.save') }}
                    </submit-button>
                </v-card-actions>
            </v-col>
        </v-row>
        <loader v-if="loading" />
    </v-form>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { updateEmailSettingsRequest } from '@/services/usersettings';
export default {
    mixins: [formRulesMixin],

    data: function () {
        return {
            loading: false,
            valid: true,
            replyToAddress: this.$store.state.auth.currentUserData.settings.replyToAddress
        };
    },

    methods: {
        async handleSubmit() {
            this.loading = true;
            try {
                await updateEmailSettingsRequest({
                    replyToAddress: this.replyToAddress
                });

                this.$notify({
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
