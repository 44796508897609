<template>
    <div>
        <v-btn
            v-for="quarter of quarterInformation"
            :key="quarter.type"
            class="mt-2 mr-2"
            small
            color="primary"
            @click="toggleQuarterClosed(quarter.type, !quarter.closed)"
        >
            <div v-if="quarter.closed">{{ quarterToText(quarter.type) }} {{ $t('general.reopen') }}</div>
            <div v-else>{{ quarterToText(quarter.type) }} {{ $t('general.close') | toLowerCase }}</div></v-btn
        >
        <loader v-if="loading" />
    </div>
</template>

<script>
// --- Components ---
// --- State ---
import { mapActions, mapState } from 'vuex';

// --- Error handling ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { getQuartersInformation, sendUpdateQuarterRequest } from '@/services/quarter';
import { Q1, Q2, Q3, Q4 } from '@/config/constants';

import { sortBy } from 'lodash';

export default {
    components: {},
    data() {
        return {
            loading: false,
            quarterInformation: {
                1: {
                    closed: false
                },
                2: {
                    closed: false
                },
                3: {
                    closed: false
                },
                4: {
                    closed: false
                }
            }
        };
    },

    computed: {
        ...mapState(['selectedYear'])
    },

    async created() {
        try {
            await this.fetchQuarterData();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('vatDeclaration', ['closeQuarter']),

        async fetchQuarterData() {
            const result = sortBy(await getQuartersInformation(this.selectedYear), (quarter) => {
                return quarter.type;
            });

            this.quarterInformation[1] = result.find((quarter) => quarter.type === Q1);
            this.quarterInformation[2] = result.find((quarter) => quarter.type === Q2);
            this.quarterInformation[3] = result.find((quarter) => quarter.type === Q3);
            this.quarterInformation[4] = result.find((quarter) => quarter.type === Q4);
        },

        quarterToText(quarter) {
            if (quarter === Q1) {
                return `${this.$t('general.quarter')} 1`;
            } else if (quarter === Q2) {
                return `${this.$t('general.quarter')} 2`;
            } else if (quarter === Q3) {
                return `${this.$t('general.quarter')} 3`;
            } else if (quarter === Q4) {
                return `${this.$t('general.quarter')} 4`;
            }
        },

        async toggleQuarterClosed(quarter, setQuarterClosedTo) {
            try {
                this.loading = true;

                await sendUpdateQuarterRequest(this.selectedYear, quarter, setQuarterClosedTo);

                notify.call(this, {
                    title: this.$t('accountantTerms.quarter_updated_sucessfuly'),
                    text: this.$t('accountantTerms.quarter_updated_sucessfuly'),
                    type: 'success'
                });

                await this.fetchQuarterData();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
