<template>
    <div v-if="taxes && currentUserData">
        <div v-if="!taxes.closed">
            <v-btn class="mt-2" small color="primary" @click="toggleTaxClosedStatus">
                {{ $t('general.close') }}
            </v-btn>
        </div>
        <div v-else>
            <v-btn class="mt-2" small color="primary" @click="toggleTaxClosedStatus">
                {{ $t('general.reopen') }}
            </v-btn>
        </div>

        <loader v-if="loading" />
    </div>
</template>

<script>
// --- Components ---
// --- State ---
import { mapState, mapActions } from 'vuex';

// --- Error handling ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import Loader from './Loader.vue';

export default {
    components: { Loader },

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapState('taxes', ['taxes']),
        ...mapState(['selectedYear'])
    },

    created() {
        this.getTaxes({
            year: this.selectedYear
        });
    },

    methods: {
        ...mapActions('taxes', ['getTaxes', 'toggleTaxStatus']),
        async toggleTaxClosedStatus() {
            try {
                this.loading = true;
                await this.toggleTaxStatus(this.selectedYear);

                notify.call(this, {
                    title: this.$t('general.tax_status_succesfuly_updated'),
                    text: this.$t('general.tax_status_succesfuly_updated')
                });

                await this.getTaxes({
                    year: this.selectedYear,
                    force: true
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
